import Modal from "@material-ui/core/Modal";
import React, { useRef, useState } from "react";
import { Backdrop, Button, CircularProgress, Fade, FormControl, Radio, FormControlLabel, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BsCalendar } from "react-icons/bs";
import { addAppointment, updateAppointment } from "../../../../../Services/AppointmentService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withSnackbar } from "../../../../../../utils/SnackbarHOC";
import EditorCustom from "../../../../../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../../../../../utils/EditorUtil";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
    width: "45em",
    padding: theme.spacing(1, 1, 1)
  },
  modalContainer: {
    padding: theme.spacing(1, 3, 2),
    maxHeight: 'calc(100vh - 30px)',
    overflowY: 'auto'
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginRight: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  takeAppointment: {
    color: "rgb(17 122 167)",
  },
}));

const apptStatus = ["Demande de coordonnées", "Refusé par client", "Refusé par candidat", "Flop", "A signé ailleurs", "Refus propale", "STB Client", "STB Candidat"];

const AppointmentModal = ({ appointment, processId, reloadData, setReloadData, lastAppointment, snackbarShowMessage }) => {
  const classes = useStyles();
  const [modalState, setModalState] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [dateIntegration, setDateIntegration] = useState(null);
  const [status, setStatus] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const title = appointment ? "Modifier le RDV" : "Ajouter un RDV";
  const active = useRef(false);

  const validate = () => {
    let temp = {}
    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setModalState(true);
  };

  const handleClose = () => {
    if (!loading) {
      setModalState(false);
    }
  };

  const handleSelected = (status) => {
    if (status !== 'Embauche/Integration') setDateIntegration(null);
    if (apptStatus.some((element) => element.toLowerCase() === status.toLowerCase())) setDate(new Date());
    setStatus(status);
  }

  const handleSubmit = () => {
    if (validate()) {
      (async () => {
        try {
          setLoading(true);
          const payload = { date, status, commentaire: removeLineBreak(commentaire), dateIntegration,candidat:"" ,selected:[]};
          const response = await addAppointment(processId, payload);
          snackbarShowMessage(response.data);
          setLoading(false);
          handleClose();
          setReloadData(!reloadData);
        } catch (error) {
          if (!active.current) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail);
          setLoading(false);
        }
      })();
    }
  };

  const handleUpdate = () => {
    if (validate()) {
      (async () => {
        try {
          setLoading(true);
          const payload = { date, processId };
          const response = await updateAppointment(payload);
          snackbarShowMessage(response.data);
          setLoading(false);
          handleClose();
          setReloadData(!reloadData);
        } catch (error) {
          if (!active.current) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail);
          setLoading(false);
        }
      })();
    }
  };

  const showDatePickers = (status) => {
    switch (status) {
      case "Embauche/Integration":
        return (
          <>
            <label className="input-label" htmlFor="idCandidat">
              Signé le <span className="required-input">*</span>
            </label>
            <KeyboardDatePicker
              style={{ marginTop: "0px" }}
              invalidDateMessage=""
              autoOk={true}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              margin="normal"
              value={date}
              onChange={(value) => {
                setDate(value);
              }} /> <br />
            <label className="input-label" htmlFor="idCandidat">
              Intègre le <span className="required-input">*</span>
            </label>
            <KeyboardDatePicker
              style={{ marginTop: "0px" }}
              invalidDateMessage=""
              autoOk={true}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              margin="normal"
              value={dateIntegration}
              onChange={(value) => {
                setDateIntegration(value);
              }} />
            <br />
          </>
        );
      case "RDV": case "Changement de date": case "Propale":
        return (
          <>
            <label className="input-label" htmlFor="idCandidat">
              DATE {status === 'Propale' ? 'PROPALE' : 'RDV'} <span className="required-input">*</span>
            </label>
            <KeyboardDatePicker
              style={{ marginTop: "0px" }}
              invalidDateMessage=""
              autoOk={true}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              margin="normal"
              value={date}
              onChange={(value) => {
                setDate(value);
              }} /><br />
          </>
        );
      default:
        return;
    }
  }

  const editorCustomProps = {
    value: commentaire,
    setValue: setCommentaire,
    enableOnBlur: false,
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.modalContainer}>
        <div className="modal-header">
          <h4 className={classes.modalTitle}>{title}</h4>
        </div>
        <div className="modal-body">
          <FormControl component="fieldset">
            <label className="input-label" htmlFor="status">
              ACTIONS
            </label>
            <RadioGroup
              row
              name="users"
              value={status}
              onChange={(e) => {
                handleSelected(e.target.value);
              }}
            >
              <FormControlLabel value="RDV" control={<Radio color="primary" />} label="Nouveau RDV" />
              {lastAppointment !== 'Révélation' && lastAppointment !== 'Envoi' ? <FormControlLabel value="Changement de date" control={<Radio color="primary" />} label="Changement de date" /> : ""}
              <FormControlLabel value="Propale" control={<Radio color="primary" />} label="Propale" />
              <FormControlLabel value="Embauche/Integration" control={<Radio color="primary" />} label="Embauche/Integration" />
            </RadioGroup>
          </FormControl>
          {showDatePickers(status)}
          <div className="input-label" htmlFor="status">
            STATUT
          </div>
          <FormControl variant="outlined" fullWidth margin="normal">
            <RadioGroup
              row
              name="users"
              value={status}
              onChange={(e) => {
                handleSelected(e.target.value);
              }}
            >
              {apptStatus.map((status, ind) => {
                return (<div key={ind} >
                  <FormControlLabel value={status.toUpperCase()} control={<Radio color="primary" />} label={status.toUpperCase()} /> <br />
                </div>
                );
              })}
            </RadioGroup>
          </FormControl>
          <label className="input-label" htmlFor="commentaire">
            Commentaire
          </label>
          <EditorCustom {...editorCustomProps} />
        </div >
        <div className="modal-footer">
          <Button className={classes.closeButton} onClick={handleClose} disabled={loading}>
            Annuler
          </Button>
          <Button className={classes.saveButton} onClick={status !== 'Changement de date' ? handleSubmit : handleUpdate} disabled={loading}>
            Sauvegarder
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </div>
      </div>
    </div >
  );

  return (
    <>
      <Button onClick={handleOpen} className={classes.takeAppointment}>
        <BsCalendar size={20} />
      </Button>
      <Modal
        className={classes.modal}
        open={modalState}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState}>{body}</Fade>
      </Modal>
    </>
  );
}
export default withSnackbar(AppointmentModal);
