import React, { useState } from "react"
import { useParams, withRouter } from "react-router-dom"
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, capitalize, useMediaQuery } from "@material-ui/core"
import Process from "./Tabs/Process"
import moment from "moment"
import "moment/locale/fr"
import { NotesOutlined, SettingsOutlined } from "@mui/icons-material"
import {
  Accordion,
  Box,
  Card,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core"
import { getDetails } from "../../Services/CandidatService"
import Notes from "./Tabs/Notes"
import "../../Contact/VisualiserContact/icons.css"
import "./visuialisation.css"
import { withSnackbar } from "../../../utils/SnackbarHOC"
import { theme } from "../../../utils/Theme";
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import 'react-reflex/styles.css'
import { LayoutContainer } from "../../../layout/LayoutContainer/LayoutContainer"
import Loader from "../../../components/Loader/Loader"
import { deleteTacheCandidat, getAll, getAllTachesFaites } from "../../Services/TacheCandidatService"
import { TABLE } from "../../../enums/tablesEnum"
import { AssignmentOutlined, LinkedIn } from "@material-ui/icons"
import { v4 } from "uuid"
import TabPanelTaches from "../../../components/TabPanelTaches/TabPanelTaches"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} px={1} pb={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  stylefont: {
    fontSize: "17px",
    fontWeight: "bold",
    padding: "0.5em",
    marginBottom: "0em",
    color: "black",
  },
  candidatHeaderInfos: {
    padding: "15px",
    marginTop: "2px",
    marginBottom: "20px",
  },
  candidatInfosCard: {
    backgroundColor: "transparent",
    padding: "0px",
  },
  infosAccordion: {
    margin: "10px",
  },
  candidatInfosBody: {
    boxSizing: "border-box",
    maxHeight: "78vh",
    height: "95%",
    paddingBottom: "20px"
  },
}))

function VisualiserCandidatDrawer({
  snackbarShowMessage,
  idCandidat,
}) {
  const [candidatDetails, setCandidatDetails] = useState({});
  const { idSelection } = useParams();
  const idSelectionDefaut = idSelection
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [loadData, setLoadData] = useState(false);
  const classes = useStyles();
  const props = { loadData, setLoadData, snackbarShowMessage, deleteTache: deleteTacheCandidat, getAll, getAllTachesFaites, idCandidat, tacheFrom: TABLE.CANDIDAT };
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const isMdMode = useMediaQuery(theme.breakpoints.between(481, 992));

  React.useEffect(() => {
    let active = true;
    let candidat;
    (async () => {
      setLoading(true);
      try {
        let params = "";
        if (idSelectionDefaut) {
          params = `?selection=${idSelectionDefaut}`;
        }
        candidat = await getDetails(idCandidat, params);
        if (!active) {
          return;
        }
        setCandidatDetails(candidat.data);
        setNom(candidat.data.nom);
        setPrenom(candidat.data.prenom);
        setEmail(candidat.data.email);
      } catch (err) {
        snackbarShowMessage("Erreur de chargement du candidat.", "error", 4000);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCandidat]);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { name: "NOTES", icon: <NotesOutlined /> },
    { name: "TÂCHES", icon: <AssignmentOutlined /> },
    { name: "PROCESS", icon: <SettingsOutlined /> },
  ]

  return (
    <LayoutContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card className={classes.candidatHeaderInfos}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  style={{
                    fontSize: !isSmMode || !isMdMode ? "26px" : "19px",
                  }}
                  sx={{
                    gap: "10px",
                    fontWeight: "600",
                    alignItems: "baseline",
                    fontFamily: "StyleFont"
                  }}
                >
                  <Box style={{
                    display: "flex",
                    color: "#117aa7",
                    alignItems: "baseLine",

                  }}>
                    <Box
                      style={{
                        marginRight: "8px",
                      }}
                    >
                      {candidatDetails.titre}
                    </Box>
                    <Box
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {candidatDetails.prenom}
                    </Box>
                    <Box
                      style={{
                        textTransform: "uppercase",
                        marginLeft: "0.5em",
                      }}
                    >
                      {candidatDetails.nom}
                    </Box>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "baseLine",
                    gap: "5px"
                  }}
                  >
                    <span
                      style={{ fontSize: !isSmMode ? "1.4rem" : "1.2rem" }}
                    >
                      N° {candidatDetails.numCandidat}
                    </span>
                    <span
                      style={isSmMode || isMdMode ? { fontSize: "14px" } : {}}
                    >
                      {candidatDetails.updatedAt
                        ? "mise à jour  :  " +
                        moment(candidatDetails.updatedAt)
                          .locale("fr")
                          .format("DD MMMM YYYY - HH:mm")
                        : "Créé le  " +
                        moment(candidatDetails.createdAt)
                          .locale("fr")
                          .format("DD MMMM YYYY - HH:mm")}
                    </span>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Box sx={{ height: (window.innerHeight - 160) }}>
            <ReflexContainer orientation="horizontal">
              <ReflexElement className="left-pane" >
                <Box className={classes.candidatInfosBody}>
                  <Card className={classes.candidatInfosCard}>
                    <Box sx={{
                      boxSizing: "border-box",
                      height: "100%",
                      paddingTop: "15px"
                    }}
                    >
                      <Accordion
                        defaultExpanded
                        className={classes.infosAccordion}
                      >
                        <h3 className={classes.stylefont}>INFORMATIONS</h3>
                        <Grid container spacing={2}>
                          {[
                            {
                              rows: [
                                { label: "TÉLÉPHONE", value: candidatDetails.telephone },
                                { label: "EMAIL", value: email },
                                {
                                  label: "LINKEDIN",
                                  value: (
                                    <a href={candidatDetails.linkedin} target="_blank" rel="noreferrer">
                                      <LinkedIn />
                                    </a>
                                  ),
                                },
                                {
                                  label: "ACCOUNT MANAGER",
                                  value: candidatDetails.accountManager
                                    ? candidatDetails.accountManager.prenom + " " + candidatDetails.accountManager.nom
                                    : "-",
                                },
                                {
                                  label: "CONSULTANT",
                                  value: candidatDetails.consultant ? candidatDetails.consultant.prenom + " " + candidatDetails.consultant.nom : "-",
                                },
                                {
                                  label: "SOCIÉTÉ",
                                  value: candidatDetails.employeur ? candidatDetails.employeur.nomSociete.toUpperCase() : "-",
                                },
                                { label: "FONCTION", value: candidatDetails.fonction },
                                { label: "SENIORITÉ", value: candidatDetails.dateDebutExperience ? candidatDetails.seniorite : "-" },
                                { label: "DISPONIBILITÉ", value: candidatDetails.preAvis ? candidatDetails.preAvis : "-" },
                                { label: "DATE DE DISPONIBILITE", value: !(['null', null, ''].includes(candidatDetails.dispoLe)) ? moment(candidatDetails.dispoLe).format("DD/MM/yyyy") : "-" },
                              ],
                            },
                          ].map((section, index) => (
                            <Grid
                              key={v4()}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              item
                              style={{
                                backgroundColor: "rgb(17 72 167 / 4%)",
                                padding: "0px 8px",
                              }}
                            >
                              <Table className="table table-striped">
                                <TableBody component="tbody">
                                  {section.rows.map((row) => (
                                    <TableRow key={v4()}>
                                      <TableCell scope="row">
                                        <strong>{row.label}</strong>
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {row.value !== "null" ? row.value : "-"}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Grid>
                          ))}
                        </Grid>
                      </Accordion>
                      <Accordion
                        defaultExpanded
                        style={{ backgroundColor: "transparent" }}
                      >
                        <Grid container spacing={2} style={!isSmMode ? { padding: "1em" } : {}}>
                          {[
                            {
                              title: "RÉMUNÉRATION ACTUELLE",
                              rows: [
                                { label: "PACKAGE", value: candidatDetails.actuellePackage, unit: "€" },
                                { label: "FIXE", value: candidatDetails.actuelleFixe, unit: "€" },
                              ]
                            },
                            {
                              title: "RÉMUNÉRATION SOUHAITÉE",
                              rows: [
                                { label: "PACKAGE", value: candidatDetails.souhaitePackage, unit: "€" },
                                { label: "FIXE", value: candidatDetails.souhaiteFixe, unit: "€" },
                              ],
                            },

                          ].map((section, index) => (
                            <Grid
                              key={v4()}
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              lg={6}
                            >
                              <Card >
                                <h3 className={classes.stylefont}>{section.title}</h3>
                                <TableContainer>
                                  <Table className="table table-striped">
                                    <TableBody component="tbody">
                                      {section.rows.map((row) => (
                                        <TableRow key={v4()}>
                                          <TableCell component="td" scope="row" style={{ fontSize: "0.8rem" }}>
                                            <strong>{row.label}</strong>
                                          </TableCell>
                                          <TableCell align="right" style={{ fontSize: "0.8rem" }}>
                                            {row.value !== null && row.value != 0 ? `${row.value} ${row.unit || ""}` : "-"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Accordion>
                    </Box>
                  </Card>
                </Box>
              </ReflexElement>
              <ReflexSplitter style={{ cursor: "move" }}>
                <Box
                  sx={{
                    backgroundColor: "#117aa7",
                    color: "white",
                    overflowX: "auto"
                  }}>
                  <Tabs
                    value={value}
                    onChange={handleTabs}
                    orientation="horizontal"
                  >
                    {
                      tabs.map(tab => (<Tab key={tab.name} style={{ minWidth: 50 }} label={
                        <Box sx={{ display: "flex", gap: 5, alignItems: "center" }} >
                          {tab.icon}
                          {!isSmMode && <span>{tab.name}</span>}
                        </Box>} />))
                    }
                  </Tabs>
                </Box>
              </ReflexSplitter>
              <ReflexElement className="right-pane" maxSize={600} minSize={50} style={{ overflow: "hidden" }}>
                <Box sx={{ background: '#ddd' }}>
                  <Box sx={{ backgroundColor: "#f4f7fc", marginTop: "40px" }}>
                    {[
                      { index: 0, component: <Notes {...props} /> },
                      { index: 1, component: <TabPanelTaches disableTableBodyMax={true} {...props} /> },
                      {
                        index: 2,
                        component: (
                          <Process
                            {...props}
                            candidatName={capitalize(prenom) + " " + nom}
                            candidatEmail={candidatDetails.email}
                            candidatTel={candidatDetails.telephone}
                            candidatAccountManager={candidatDetails.accountManager}
                            candidatConsultant={candidatDetails.consultant}
                            candidatSeniorite={candidatDetails.seniorite}
                            candidatCommentaire={candidatDetails.commentaire}
                            candidatPointFort={candidatDetails.pointsforts}
                            candidatSoftskills={candidatDetails.softskills}
                            candidatLangue2={candidatDetails.langue2}
                            candidatAnglais={candidatDetails.anglais}
                            candidatActuPackage={candidatDetails.actuellePackage}
                            candidatActuFixe={candidatDetails.actuelleFixe}
                            candidatActuVariable={candidatDetails.actuelleVariable}
                            candidatActuInteress={candidatDetails.actuelleInteressement}
                            candidatSouhaitePackage={candidatDetails.souhaitePackage}
                            candidatsouhaiteFixe={candidatDetails.souhaiteFixe}
                            candidatsouhaiteVariable={candidatDetails.souhaiteVariable}
                            candidatsouhaiteInteress={candidatDetails.souhaiteInteress}
                            candidatpreAvis={candidatDetails.preAvis}
                            candidatdispoLe={candidatDetails.dispoLe}
                            numCandidat={candidatDetails.numCandidat}
                          />
                        ),
                      },

                    ].map(({ index, component }) => (
                      <TabPanel key={v4()} value={value} index={index}>
                        {component}
                      </TabPanel>
                    ))}
                  </Box>
                </Box>
              </ReflexElement>
            </ReflexContainer>
          </Box>
        </>
      )
      }
    </LayoutContainer>
  )
}
export default withSnackbar(withRouter(VisualiserCandidatDrawer));
