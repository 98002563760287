import { Card, Grid, makeStyles, Table, TableBody, useMediaQuery, TableCell, TableContainer, TableRow } from "@material-ui/core";
import moment from "moment";

import React from "react";
import { theme } from "../../../../utils/Theme";
import { v4 } from "uuid";

const useStyles = makeStyles({
  cards: {
    height: "auto",
  },
  tabTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    padding: "0.5em",
    color: "black",
    backgroundColor: "white"
  },
  backtable: {
    backgroundColor: "rgb(17 72 167 / 4%)",
    boxShadow: "rgb(0 0 0 / 80%) 0px 0px 15px",
  },
});

export default function PostRecherche({
  actuellePackage,
  actuelleFixe,
  actuelleVariable,
  actuelleInteressement,
  actuelleAutres,
  souhaiteAutres,
  souhaiteFixe,
  souhaiteInteressement,
  souhaitePackage,
  souhaiteVariable,
  dispoLe,
  preAvis
}) {

  const classes = useStyles();

  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  return (
    <Grid container spacing={2} style={!isSmMode ? { padding: "1em" } : {}}>
      {[
        {
          title: "RÉMUNÉRATION ACTUELLE",
          rows: [
            { label: "PACKAGE", value: actuellePackage, unit: "€" },
            { label: "FIXE", value: actuelleFixe, unit: "€" },
            { label: "VARIABLE", value: actuelleVariable, unit: "€" },
            { label: "INTÉRESSEMENT/PARTICIPATION", value: actuelleInteressement },
            { label: "AUTRES", value: actuelleAutres, unit: "€" },
          ],
        },
        {
          title: "RÉMUNÉRATION SOUHAITÉE",
          rows: [
            { label: "PACKAGE", value: souhaitePackage, unit: "€" },
            { label: "FIXE", value: souhaiteFixe, unit: "€" },
            { label: "VARIABLE", value: souhaiteVariable, unit: "€" },
            { label: "AUTRES", value: souhaiteAutres, unit: "€" },
          ],
        },
        {
          title: "DISPONIBILITÉ",
          rows: [
            { label: "PRÉAVIS", value: preAvis },
            { label: "DATE DE DISPONIBILITE", value: !(['null', null, ''].includes(dispoLe)) ? moment(dispoLe).format("DD/MM/yyyy") : "-" },
          ],
        },
      ].map((section, index) => (
        <Grid
          key={v4()}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
        >
          <Card>
            <h3 className={classes.tabTitle}>{section.title}</h3>
            <TableContainer>
              <Table className="table table-striped">
                <TableBody component="tbody">
                  {section.rows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell component="td" scope="row" style={{ fontSize: "0.8rem" }}>
                        <strong>{row.label}</strong>
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: "0.8rem" }}>
                        {row.value !== null && row.value != 0 ? `${row.value} ${row.unit || ""}` : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
