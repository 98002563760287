
const data = [
    { name: "LINKEDIN", id: 1 },
    { name: "COOPTATION", id: 2 },
    { name: "EMAILING", id: 4 },
    { name: "PHONING", id: 5 },
    { name: "RELATIONS ECOLES", id: 8 },
    { name: "CVTHEQUE", id: 9 },
    { name: "AUTRE", id: 10 },
]
export const sources = data.sort((a, b) => a.name.localeCompare(b.name));