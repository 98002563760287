import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {loginUser} from "../Services/AuthentificationService";
import {Card, CircularProgress} from "@material-ui/core";
import useToken from "../../utils/useToken";
import {Redirect, useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login({snackbarShowMessage}) {
  const classes = useStyles();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);
  const {setToken} = useToken();
  const [redirectToReferrer, setRedirectToReferrer] = useState(localStorage.getItem("token") ? true : false);
  const {state} = useLocation();
  const [errors, setErrors] = useState({});
  const validate = () => {
    let temp = {};
    temp.username = username ? "" : "'ADRESSE EMAIL' est obligatoire.";
    temp.password = password ? "" : "'MOT DE PASSE' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
          const {data} = await loginUser({
           username,
          password,
        });
        localStorage.setItem("rolecurrentuser",data.data.role[0]);
        if (!mounted.current) {
          return;
        }
        setToken(data);
        localStorage.setItem("userData", JSON.stringify(data.data));
        snackbarShowMessage(
          "Connexion réussie",
          "success",
          4000
        );
        setRedirectToReferrer(true);
      } catch (error) {
        snackbarShowMessage(
          error.response.data.error.detail,
          "error",
          4000
        );
      } finally {
        setLoading(false);
      }
    }
  };
  if (redirectToReferrer) {
    return <Redirect to={state?.from || "/"} />;
  }

  return (
    <Container component="main" maxWidth="xs" style={{marginTop: "16em"}}>
      <CssBaseline />
      <Card>
        <div className={classes.paper}>
          <img src="/assets/images/peoplefine-logo.png" alt="People fine Logo" style={{width: "200px"}} className="peoplef-inelogo" />
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Adresse email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
              {...(errors.username && {
                error: true,
                label: errors.username,
              })}
            />
            <br /> <br />
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyPress}
              {...(errors.password && {
                error: true,
                label: errors.password,
              })}
            />
            <br />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Se souvenir de moi" />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={loading}>
              {loading ? <CircularProgress size={16} /> : "Se Connecter"}
            </Button>
            <Grid container>
              <Grid item={true} >
                <Link href="#" variant="body2">
                  Mot de passe oublié?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Card>
    </Container>
  );
}
