import React, { useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Etape1 } from "./Components/etape1";
import { Etape2 } from "./Components/etape2";
import { Etape3 } from "./Components/etape3";
import "./addcandidat.css";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { addCandidat, getDetails, editCandidat } from "../../Services/CandidatService";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import SaveBar from "./Components/SaveBar";
import { addremovecandidattoselection } from "../../Services/SelectionCandidatService";
import { removeLineBreak } from "../../../utils/EditorUtil";
import { theme } from "../../../utils/Theme";
import Loader from "../../../components/Loader/Loader";
import { useQuery } from "../../../utils/FunctionHelpers";
import ShowCVCandidat from "../VisualiserCandidat/ShowCVCandidat";

const CandidatForm = ({ editMode, history, snackbarShowMessage }) => {
  let query = useQuery();
  const [datedebut, setDateDebut] = useState();
  const [linkedin, setLinkedin] = useState("");
  const [titre, setTitre] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [employeur, setEmployeur] = useState(undefined);
  const [fonction, setFonction] = useState("");
  const [manager, setManager] = useState("");
  const [consultant, setConsultant] = useState("");
  const [sachezque, setSachezQue] = useState("");
  const [disponibilite, setDisponibilite] = useState();
  const [competencesSectorielles, setCompetencesSectorielles] = useState([]);
  const [candidatSelectionsData, setCandidatSelectionsData] = useState([])
  const [competencesMetiers, setCompetencesMetiers] = useState([]);
  const [competencesTechniques, setCompetencesTechniques] = useState("");
  const [ecole, setEcole] = useState("");
  const [anglais, setAnglais] = useState("");
  const [langue2, setLangue2] = useState("");
  const [actuPackage, setActuPackage] = useState("");
  const [actuFixe, setActuFixe] = useState("");
  const [actuVariable, setActuVariable] = useState("");
  const [actuInteressement, setActuInteressement] = useState("");
  const [actuAutres, setActuAutres] = useState("");
  const [souhaitPackage, setsouhaitPackage] = useState("");
  const [souhaitFixe, setSouhaitFixe] = useState("");
  const [souhaitVariable, setSouhaitVariable] = useState("");
  const [souhaitInteressement, setSouhaitInteressement] = useState("");
  const [souhaitAutres, setSouhaitAutres] = useState("");
  const [preAvis, setPreAvis] = useState("");
  const [dispoLe, setDispoLe] = useState(null);
  const [errors, setErrors] = useState({});
  const [softskills, setSoftskills] = useState('');
  const [commentaire, setCommentaire] = useState('');
  const [pointsforts, setPointsforts] = useState('');
  const [cv, setCv] = useState([]);
  const [cvFormate, setCvFormate] = useState([]);
  const [autresPieces, setAutresPieces] = useState([]);
  const [seniorite, setSeniorite] = useState(undefined);
  const title = editMode ? prenom + " " + nom : "Ajouter un candidat.";
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const { idCandidat } = useParams();
  const [oldCvs, setOldCvs] = useState([]);
  const [oldCvsFormate, setOldcvsFormate] = useState([]);
  const [oldAutresPieces, setOldAutresPieces] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [source, setSource] = useState(null);
  const [reloadData, setReloadData] = useState(false);

  React.useEffect(() => {
    let active = true;

    const fetchData = async () => {
      setLoadingData(true);
      try {
        if (editMode) {
          const { data } = await getDetails(idCandidat);
          if (active) {
            setLinkedin(data.linkedin);
            setTitre(data.titre);
            setPrenom(data.prenom);
            setNom(data.nom);
            setEmail(data.email);
            setTelephone(data.telephone);
            setEmployeur(data.employeur || "");
            setFonction(data.fonction);
            setDateDebut(data.dateDebutExperience ? new Date(data.dateDebutExperience) : null);
            setSeniorite(data.seniorite);
            setManager(data.accountManager ? data.accountManager.uuid : "");
            setConsultant(data.consultant ? data.consultant.uuid : "");
            setSachezQue(data.sachezQue);
            setCompetencesSectorielles(data.competenceSectorielle || "");
            setCompetencesTechniques(data.competencesTechniques || "");
            setCompetencesMetiers(data.competenceMetier);
            setEcole(data.ecole);
            setAnglais(data.anglais);
            setLangue2(data.langue2);
            setsouhaitPackage(data.souhaitePackage);
            setSouhaitFixe(data.souhaiteFixe);
            setSouhaitVariable(data.souhaiteVariable);
            setSouhaitInteressement(data.souhaiteInteressemVisualiserCandidatent);
            setSouhaitAutres(data.souhaiteAutres);
            setActuPackage(data.actuellePackage);
            setActuVariable(data.actuelleVariable);
            setActuFixe(data.actuelleFixe);
            setActuInteressement(data.actuelleInteressement);
            setActuAutres(data.actuelleAutres);
            setCommentaire(data.commentaire);
            setSoftskills(data.softskills);
            setPointsforts(data.pointsforts);
            setDispoLe(data.dispoLe ?? null);
            setPreAvis(data.preAvis ?? null);
            setOldFiles(data.uploadedFiles);
            setSource(data.source)
          }
        }
      } catch (error) {
        snackbarShowMessage(`Error fetching data:${error?.message}`, "error", 4000);
      } finally {
        if (active) {
          setLoadingData(false);
        }
      }
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [idCandidat]);


  const validate = () => {
    let temp = {};
    temp.linkedin = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/.test(linkedin) ? "" : "le champ 'LINKEDIN' n'est pas valide.";
    temp.titre = titre?.trim()?.match(/^(Mme|Mr)$/) ? "" : "Le champ 'TITRE' est obligatoire.";
    temp.prenom = prenom ? "" : "Le champ 'PRÉNOM' est obligatoire.";
    temp.source = source ? "" : "Le champ 'SOURCE' est obligatoire.";
    temp.nom = nom ? "" : "Le champ 'NOM' est obligatoire.";
    temp.seniorite = seniorite < 0 ? "Veuillez choisir une date <ou= à celle d'aujourd'hui." : "";
    temp.fonction = fonction ? "" : "Le champ 'FONCTION ' est obligatoire.";
    temp.datedebut = datedebut ? "" : "Le champ 'DATE DÉBUT EXPÉRIENCE' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (!validate()) return
    setLoading(true);
    const formData = new FormData();
    (async () => {
      cv.map((cvFile) => formData.append("cv[]", cvFile));
      cvFormate.map((cvFormateFile) => formData.append("cvFormate[]", cvFormateFile));
      autresPieces.map((autrePiece) => formData.append("autresPieces[]", autrePiece));
      formData.append("linkedin", linkedin);
      formData.append("titre", titre);
      formData.append("nom", nom);
      formData.append("prenom", prenom);
      formData.append("email", email);
      formData.append("telephone", telephone);
      formData.append("employeur", employeur ? employeur.uuid : "");
      formData.append("fonction", fonction);
      formData.append("datedebut", datedebut ?? "");
      formData.append("seniorite", seniorite);
      formData.append("manager", manager);
      formData.append("consultant", consultant);
      formData.append("sachezque", sachezque);
      formData.append("source", source);
      competencesSectorielles.map((secteur) => formData.append('competencesSectorielles[]', secteur));
      competencesMetiers.map((metier) => formData.append('competencesMetiers[]', metier));
      formData.append("competencesTechniques", competencesTechniques);
      formData.append("ecole", ecole && ecole.id ? ecole.id : "");
      formData.append("anglais", anglais);
      formData.append("langue2", langue2);
      formData.append("actuPackage", actuPackage);
      formData.append("actuFixe", actuFixe);
      formData.append("actuVariable", actuVariable);
      formData.append("actuInteressement", actuInteressement);
      formData.append("actuAutres", actuAutres);
      formData.append("preAvis", preAvis);
      formData.append("dispoLe", dispoLe ?? null);
      formData.append("souhaitPackage", souhaitPackage);
      formData.append("souhaitFixe", souhaitFixe);
      formData.append("souhaitVariable", souhaitVariable);
      formData.append("souhaitInteressement", souhaitInteressement);
      formData.append("souhaitAutres", souhaitAutres);
      formData.append("commentaire", removeLineBreak(commentaire));
      formData.append("softskills", removeLineBreak(softskills));
      formData.append("pointsforts", removeLineBreak(pointsforts));
      try {
        snackbarShowMessage("Enregistrement en cours...", "warning", null);
        const { data } = await addCandidat(formData)
        await addremovecandidattoselection({
          selectedSelections: candidatSelectionsData
        }, data.newCandidatId)
        history.push({
          pathname: `/candidats/${data.newCandidatId}/view`,
          state: { snack: true, msg: data },
        });
        snackbarShowMessage("Candidat modifié avec succès.", "success", 4000);
      } catch (err) {
        snackbarShowMessage(err.response.data.error.detail, "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleEdit = () => {
    if (!validate()) return
    const formData = new FormData();
    (async () => {
      setLoading(true);
      formData.append("linkedin", linkedin);
      formData.append("titre", titre);
      formData.append("nom", nom);
      formData.append("prenom", prenom);
      formData.append("email", email);
      formData.append("telephone", telephone);
      formData.append("employeur", employeur ? employeur.uuid : "");
      formData.append("fonction", fonction);
      formData.append("datedebut", datedebut ?? "");
      formData.append("seniorite", seniorite);
      formData.append("manager", manager);
      formData.append("consultant", consultant);
      formData.append("sachezque", sachezque);
      formData.append("source", source);
      competencesSectorielles.map((secteur) => formData.append('competencesSectorielles[]', secteur));
      competencesMetiers.map((metier) => formData.append('competencesMetiers[]', metier));
      formData.append("competencesTechniques", competencesTechniques);
      formData.append("ecole", ecole && ecole.id ? ecole.id : "");
      formData.append("anglais", anglais);
      formData.append("langue2", langue2);
      formData.append("actuPackage", actuPackage);
      formData.append("actuFixe", actuFixe);
      formData.append("actuVariable", actuVariable);
      formData.append("actuInteressement", actuInteressement);
      formData.append("actuAutres", actuAutres);
      formData.append("preAvis", preAvis);
      formData.append("dispoLe", dispoLe ?? null);
      formData.append("souhaitPackage", souhaitPackage);
      formData.append("souhaitFixe", souhaitFixe);
      formData.append("souhaitVariable", souhaitVariable);
      formData.append("souhaitInteressement", souhaitInteressement);
      formData.append("souhaitAutres", souhaitAutres);
      formData.append("commentaire", removeLineBreak(commentaire));
      formData.append("softskills", removeLineBreak(softskills));
      formData.append("pointsforts", removeLineBreak(pointsforts));
      oldFiles.map((oldFile) => formData.append("oldFiles[]", oldFile.id));
      cv.map((cvFile) => formData.append("cvs[]", cvFile));
      cvFormate.map((cvFormateFile) => formData.append("cvsFormates[]", cvFormateFile));
      autresPieces.map((autrePiece) => formData.append("autresPieces[]", autrePiece));
      try {
        snackbarShowMessage("Modification en cours...", "warning", null);
        await editCandidat(formData, idCandidat);
        await addremovecandidattoselection({
          selectedSelections: candidatSelectionsData
        }, idCandidat)
        history.goBack()
        snackbarShowMessage("Candidat modifié avec succès.", "success");
      } catch (err) {
        snackbarShowMessage(err.response.data.error.detail, "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
  };

  const etape1 = {
    idCandidat,
    linkedin,
    titre,
    nom,
    prenom,
    telephone,
    email,
    employeur,
    fonction,
    datedebut,
    seniorite,
    manager,
    consultant,
    sachezque,
    setLinkedin,
    setTitre,
    setPrenom,
    setNom,
    setTelephone,
    setEmail,
    setEmployeur,
    setFonction,
    setDateDebut,
    setSeniorite,
    setManager,
    setConsultant,
    setSachezQue,
    errors,
    setErrors,
    editMode,
    handleSubmit,
    handleEdit,
    snackbarShowMessage,
    loading,
    source, setSource
  };

  const etape2 = {
    idCandidat,
    competencesSectorielles,
    competencesMetiers,
    disponibilite,
    competencesTechniques,
    ecole,
    anglais,
    langue2,
    actuPackage,
    actuFixe,
    actuVariable,
    actuInteressement,
    actuAutres,
    souhaitPackage,
    souhaitFixe,
    souhaitVariable,
    souhaitInteressement,
    souhaitAutres,
    candidatSelectionsData,
    setCandidatSelectionsData,
    setDisponibilite,
    setCompetencesSectorielles,
    setCompetencesMetiers,
    setCompetencesTechniques,
    setEcole,
    setAnglais,
    setLangue2,
    setActuPackage,
    setActuFixe,
    setActuVariable,
    setActuInteressement,
    setActuAutres,
    setsouhaitPackage,
    setSouhaitFixe,
    setSouhaitVariable,
    setSouhaitInteressement,
    setSouhaitAutres,
    preAvis,
    setPreAvis,
    errors,
    setErrors,
    editMode,
    dispoLe,
    setDispoLe,
    handleSubmit,
    handleEdit,
    snackbarShowMessage,
    loading,
    setReloadData,
    reloadData,
  };
  const etape3 = {
    idCandidat,
    softskills,
    setSoftskills,
    commentaire,
    setCommentaire,
    pointsforts,
    setPointsforts,
    cv,
    setCv,
    cvFormate,
    setCvFormate,
    autresPieces,
    setAutresPieces,
    errors,
    setErrors,
    editMode,
    handleSubmit,
    loading,
    oldCvs,
    oldCvsFormate,
    oldAutresPieces,
    setOldCvs,
    setOldcvsFormate,
    setOldAutresPieces,
    handleEdit,
    oldFiles,
    setOldFiles
  };
  const saveBarProps = {
    handleSubmit, editMode, handleEdit, loading, idCandidat
  }
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  return (
    <Box sx={{ padding: "0 20px " }}>

      {loadingData ? (
        <Loader />
      ) : (
        <>
          <Box className="candidatedit_header">
            <Grid container spacing={1} direction={isSmMode ? "column-reverse" : ""} >
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <h4>{title + " - N°" + query.get('num-candidat')}</h4>
                  {editMode && <ShowCVCandidat
                    {...{
                      idCandidat: query.get('idCandidat'),
                      snackbarShowMessage,
                      files: oldFiles,
                      editMode
                    }}
                  />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <SaveBar item {...saveBarProps} />
              </Grid>
            </Grid>
          </Box>

          <Box className="candidatedit_container">
            <Grid container spacing={1}>
              <Etape1 {...etape1} />
              <Etape2 {...etape2} />
              <Etape3 {...etape3} />
            </Grid>
          </Box>
        </>

      )}
    </Box>
  );
};

export default withSnackbar(withRouter(CandidatForm));
