import {
  CircularProgress,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";
import React, { useState, useRef } from "react";
import MissionModal from "../Controls/MissionModal";
import MUIDataTable from "mui-datatables";
import { useParams } from "react-router-dom";
import {
  getAllProcesses,
  deleteProcess,
} from "../../../Services/ProcessService";
import { terminerMission } from "../../../Services/MissionService";
import moment from "moment";
import AppointmentModal from "./Modals/CandidatProcess/AppointmentModal";
import SendCandidatToClientModal from "./Modals/CandidatProcess/SendCandidatToClientModal";
import AppointmentsListModal from "./Modals/CandidatProcess/AppointmentsListModal";
import "../visuialisation.css";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import DialogDeleteCustom from "../../../../components/DialogDeleteCustom/DialogDeleteCustom";

const Process = ({
  setAutresPieces,
  candidatName,
  candidatEmail,
  candidatTel,
  candidatAccountManager,
  candidatConsultant,
  candidatSeniorite,
  candidatCommentaire,
  candidatPointFort,
  candidatSoftskills,
  candidatLangue2,
  candidatAnglais,
  candidatActuPackage,
  candidatActuFixe,
  candidatActuVariable,
  candidatActuInteress,
  candidatSouhaitePackage,
  candidatsouhaiteFixe,
  candidatsouhaiteVariable,
  candidatsouhaiteInteress,
  candidatpreAvis,
  candidatdispoLe,
  numCandidat,
  snackbarShowMessage,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const parametters = useParams();
  const idCandidat = rest.idCandidat ?? parametters.idCandidat;
  const [missionPassive, setMissionPassive] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [loadingtoFinish, setLoadingToFinish] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [missionInfos, setMissionInfos] = useState({ id: "", nom: "" });
  const [openFinishDialog, setOpenFinishDialog] = useState(false);
  const missionModalProps = {
    processes,
    setReloadData,
    reloadData,
    snackbarShowMessage,
  };
  const apptModalProps = { reloadData, setReloadData };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isActive = useRef();

  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  const handleCloseFinishDialog = () => {
    if (!loadingtoFinish) {
      setOpenFinishDialog(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    if (!loadingToDelete) {
      setOpenDeleteDialog(false);
    }
  };

  const handleDeleteProcess = (e, params) => {
    e.preventDefault();
    setMissionInfos({ id: params.rowData[10], nom: params.rowData[3] });
    setOpenDeleteDialog(true);
  };

  const handleFinishMission = () => {
    if (!loadingtoFinish) {
      setLoadingToFinish(true);
      (async () => {
        try {
          const { data } = await terminerMission(missionInfos.id);
          if (!isActive.current) {
            return;
          }
          snackbarShowMessage(data);
          setLoadingToFinish(false);
          handleCloseFinishDialog();
          setReloadData(!reloadData);
        } catch (error) {
          if (!isActive.current) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail, "error", 4000);
          setLoadingToFinish(false);
        }
      })();
    }
  };

  const deleteProcessFromCandidat = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        const { data } = await deleteProcess(missionInfos.id);
        if (!isActive.current) {
          return;
        }
        snackbarShowMessage(data);
        setLoadingToDelete(false);
        handleCloseDeleteDialog();
        setReloadData(!reloadData);
      } catch (error) {
        snackbarShowMessage(error.response.data.error.detail, "error", 4000);
        setLoadingToDelete(false);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setProcesses([]);
        const status = missionPassive
          ? "bad58bee-1b80-448c-9010-03cad8526233"
          : "910f357b-0b13-4f66-89a8-5c06964c1dcf";
        const newProccessesActive = await getAllProcesses(
          idCandidat,
          page,
          50,
          status
        );
        setProcesses(newProccessesActive.data.processes);
        setCount(newProccessesActive.data.count);
      } catch (error) {
        snackbarShowMessage(error.response.data.error.detail, "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, missionPassive, idCandidat, reloadData]);

  React.useEffect(() => {
    isActive.current = true;
    return () => {
      isActive.current = false;
    };
  });

  const columns = [
    {
      name: "missionId",
      label: "MISSION ID",
      options: {
        display: false,
      },
    },
    {
      name: "nom_societe",
      label: "SOCIETE",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            padding: "0px 12px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            maxWidth: "200px",
            padding: "2px 12px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box data-toggle="tooltip" title={value}>
              <Link
                to={{ pathname: `/societe/${tableMeta.rowData[11]}/view` }}
                className="d-print-none font-sm"
                style={{ textDecoration: "none" }}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STATUT",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
            padding: "0px 12px",
          },
        }),
        setCellHeaderProps: () => ({
          style: { justifyContent: "space-around", padding: "0px 12px" },
        }),
      },
    },
    {
      name: "numMission",
      label: "MISSION N°",
      options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            padding: "0px 12px",
          }
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Link
                to={{
                  pathname: `/societe/${tableMeta.rowData[11]}/missions/${tableMeta.rowData[0]}/view`,
                }}
                className="d-print-none font-sm"
                style={{ textDecoration: "none" }}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "nom_mission",
      label: "MISSION",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
            padding: "0px 12px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            maxWidth: "100px",
            padding: "2px 12px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box data-toggle="tooltip" title={value}>
              <Link
                to={{
                  pathname: `/societe/${tableMeta.rowData[11]}/missions/${tableMeta.rowData[0]}/view`,
                }}
                className="d-print-none font-sm"
                style={{ textDecoration: "none" }}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "sent",
      label: "ENVOYE",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <SendCandidatToClientModal
                {...{
                  reloadData,
                  setReloadData,
                  candidatName,
                  setAutresPieces,
                  missions: processes,
                  selectedMission: tableMeta.rowData[0],
                  sentDate:
                    value && value.date
                      ? moment(value.date).format("DD/MM/yyyy")
                      : "Envoyer",
                  candidatAccountManager,
                  candidatConsultant,
                  candidatSeniorite,
                  candidatCommentaire,
                  candidatPointFort,
                  candidatSoftskills,
                  candidatLangue2,
                  candidatAnglais,
                  candidatActuPackage,
                  candidatActuFixe,
                  candidatActuVariable,
                  candidatActuInteress,
                  candidatSouhaitePackage,
                  candidatsouhaiteFixe,
                  candidatsouhaiteVariable,
                  candidatsouhaiteInteress,
                  candidatpreAvis,
                  candidatdispoLe,
                  numCandidat,
                }}
                processId={
                  processes.find(
                    (proces) => proces.missionId === tableMeta.rowData[0]
                  ).processId
                }
                key={tableMeta.rowData[0]}
                isMissionActive={tableMeta.rowData[2] === "ACTIVE"}
              />
              {value ? (
                ""
              ) : (
                <>
                  {" | "}
                  <a
                    href="#/"
                    onClick={(e) => handleDeleteProcess(e, tableMeta)}
                    style={{ textDecoration: "none", color: "red" }}
                  >
                    X
                  </a>
                </>
              )}
            </>
          );
        },
      },
    },
    {
      name: "revealed",
      label: "REVELE",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SendCandidatToClientModal
              {...{
                reloadData,
                setReloadData,
                candidatName,
                setAutresPieces,
                missions: processes,
                selectedMission: tableMeta.rowData[0],
                sentDate:
                  tableMeta.rowData[5] && tableMeta.rowData[5].date
                    ? moment(tableMeta.rowData[5].date).format("DD/MM/yyyy")
                    : "",
                revealDate:
                  value && value.date
                    ? moment(value.date).format("DD/MM/yyyy")
                    : "Révéler",
                forReveal: true,
                countReveal: tableMeta.rowData[6],
                numCandidat,
              }}
              key={tableMeta.rowData[0]}
              isMissionActive={tableMeta.rowData[2] === "ACTIVE"}
              {...{ candidatEmail, candidatTel, numCandidat }}
              processId={
                processes.find(
                  (proces) => proces.missionId === tableMeta.rowData[0]
                ).processId
              }
            />
          );
        },
      },
    },
    {
      name: "countReveal",
      label: "RELANCES",
      options: {
        display: false,
      },
    },
    {
      name: "step",
      label: "STEP",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {
                <AppointmentsListModal
                  {...{
                    proces: tableMeta.rowData[10],
                    status: value,
                    reloadProcess: setReloadData,
                    snackbarShowMessage,
                  }}
                />
              }
            </>
          );
        },
      },
    },
    {
      name: "lastAppointment",
      label: "DATE/STEP",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value
                ? tableMeta.rowData[8] === "Embauche/Integration"
                  ? moment(value).format("DD/MM/yyyy") +
                  "-" +
                  moment(tableMeta.rowData[13]).format("DD/MM/yyyy")
                  : moment(value).format("DD/MM/yyyy")
                : "Aucun"}
            </>
          );
        },
      },
    },
    {
      name: "processId",
      label: "N° PROCESS",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "societeId",
      label: "N° SOCIETE",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {tableMeta.rowData[2] !== "PASSIVE" ? (
                <>
                  {tableMeta.rowData[4] ? (
                    <AppointmentModal
                      {...apptModalProps}
                      processId={
                        processes.find(
                          (proces) => proces.missionId === tableMeta.rowData[0]
                        ).processId
                      }
                      lastAppointment={tableMeta.rowData[8]}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </>
          );
        },
      },
    },
    {
      name: "dateIntegration",
      label: "dateIntegration",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "0px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("DD/MM/yyyy")}</>;
        },
      },
    },
  ];

  const options = {
    serverSide: true,
    count: count,
    rowsPerPage: 50,
    tableBodyMaxHeight: "44vh",
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    columns: false,
    search: false,
    download: false,
    viewColumns: false,
  };

  const dialogAcheverProps = {
    handleClose: handleCloseFinishDialog,
    handleOpen: openFinishDialog,
    handleDelete: handleFinishMission,
    loading: loadingtoFinish,
    label: ` Voulez vous vraiment achever la mission: ${missionInfos.nom}?`,
    title: "Achever la mission",
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDeleteDialog,
    handleOpen: openDeleteDialog,
    handleDelete: deleteProcessFromCandidat,
    loading: loadingToDelete,
    label: `Voulez vous supprimer le process: ${missionInfos.nom}?`,
    title: "Supprimer le process?",
  };

  return (
    <>
      <DialogDeleteCustom {...dialogAcheverProps} />
      <DialogDeleteCustom {...dialogDeleteProps} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <MissionModal {...missionModalProps} />
        <Box>
          <label className="input-label" htmlFor="tachesTermines">
            MISSIONS PASSIVES
          </label>
          <FormControlLabel
            control={
              <Switch
                checked={missionPassive}
                onChange={(e) => setMissionPassive(e.target.checked)}
                name="missionPassives"
                color="primary"
              />
            }
            style={{ marginLeft: "0.2em", marginRight: 0 }}
          />
        </Box>
      </div>
      <MUIDataTable data={processes} columns={columns} options={options} />
    </>
  );
};

export default withSnackbar(Process);
