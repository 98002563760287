import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditorCustom from "../EditorCustorm/EditorCustom";
import LoadingButtonCustom from "../LoadingButtonCustom/LoadingButtonCustom";
import { useEffect } from "react";
import { AttachFile } from "@material-ui/icons";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { sendEmailsToCandidatsOfSelection } from "../../pages/Services/SelectionCandidatService";
import { removeLineBreak } from "../../utils/EditorUtil";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { useDispatch } from "react-redux";
import { setToggleMailing } from "../../redux/toggle/actions/toggleActions";
import { ClearListCandidatsSelectedForSelection } from "../../redux/candidats/actions/candidatsActions";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    borderRadius: "20px",
    width: "550px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
}));

function MailingModal({ snackbarShowMessage }) {
  const classes = useStyles();

  const [messageBody, setMessageBody] = useState("");
  const [objet, setObjet] = useState("");
  const uploadRef = useRef(null);
  const [dataUpload, setDataUpload] = useState();
  const [loadingToSend, setLoadingToSend] = useState(false);
  const [errors, setErrors] = useState({});
  const selectedCandidatForSelection = useSelector(
    (state) => state.candidats.candidatsSelectedForSelection
  );
  const showMailingModal = useSelector((state) => state.toggle.showMailing);
  const dispatch = useDispatch();

  useEffect(() => {
    setMessageBody("Bonjour [PRENOM],<br></br><p>Bien cordialement</p>");
    setErrors({});
  }, [showMailingModal]);

  const validate = ({ messageBody, objet }) => {
    let temp = {};
    temp.commentaire = !(messageBody?.replace(/<\/?[^>]+(>|$)/g, "") === "")
      ? ""
      : "Le champ 'MESSAGE' est obligatoire.";
    temp.objet = !(objet?.replace(/<\/?[^>]+(>|$)/g, "") === "")
      ? ""
      : "Le champ 'OBJET' est obligatoire.";
    setErrors({
      ...temp,
    });
    return !Object.values({ ...temp }).some((error) => error !== "");
  };

  const handleSubmit = ({ messageBody, objet, pj }) => {
    if (validate({ messageBody, objet })) {
      (async () => {
        setLoadingToSend(true);
        const formData = new FormData();
        formData.append("messageBody", removeLineBreak(messageBody));
        formData.append("objet", objet);
        formData.append("pj", pj);
        formData.append(
          "selectedCandidatToSendMail",
          selectedCandidatForSelection
        );
        try {
          snackbarShowMessage("Patientez svp!", "warning", null);
          const response = await sendEmailsToCandidatsOfSelection(formData);
          snackbarShowMessage(selectedCandidatForSelection.length + " Emails envoyé avec success.", "success", 2000);
          dispatch(ClearListCandidatsSelectedForSelection());
        } catch (e) {
          snackbarShowMessage(e.response.data.error.detail, "error", 4000);
          setLoadingToSend(false);
        } finally {
          setLoadingToSend(false);
          dispatch(setToggleMailing(false));
        }
      })();
    }
  };

  const editorCustomProps = {
    value: messageBody,
    setValue: setMessageBody,
    handleSubmit,
    enableOnBlur: false,
  };

  const loadingButtonProps = {
    handleSubmit: () => handleSubmit({ messageBody, objet, pj: dataUpload }),
    loading: loadingToSend,
    title: "Envoyer",
  };

  const body = (
    <Box className={classes.paper}>
      <h4 className={classes.modalTitle}>Mailing</h4>
      <Box sx={{ paddingBottom: "16px" }}>
        <label className="input-label" htmlFor="prenom">
          Objet<span className="required-input">*</span>
        </label>
        <TextField
          onChange={(e) => setObjet(e.target.value)}
          name={objet}
          value={null}
          placeholder="Objet du mail."
          margin="normal"
          variant="outlined"
          autoComplete="off"
          fullWidth
          {...(errors.objet && {
            error: true,
            label: errors.objet,
          })}
        />
        <Box className="input-label">
          Message <span className="required-input">*</span>
        </Box>
        <EditorCustom {...editorCustomProps} />
        {errors.commentaire && (
          <Typography variant="body2" color="error">
            {errors.commentaire}
          </Typography>
        )}
        {dataUpload && (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              height: "100%",
            }}
            className="input-label"
          >
            <span>PJ:</span>
            <Chip
              label={dataUpload.name}
              variant="default"
              onDelete={() => setDataUpload(null)}
              size="small"
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "end",
          width: "100%",
        }}
        className="modal-footer"
      >
        {!dataUpload && (
          <Box sx={{ display: "flex" }}>
            <input
              type="file"
              onChange={(e) => setDataUpload(e.target.files[0])}
              style={{ display: "none" }}
              ref={uploadRef}
            />
            <IconButton
              // disabled={value.isMail || loading}
              onClick={() => uploadRef.current.click()}
              data-toggle="tooltip"
              title="Ajouter PJ"
            >
              <AttachFile style={{ fontSize: "20px" }} color="primary" />
            </IconButton>
          </Box>
        )}

        <Button
          className={classes.closeButton}
          onClick={() => {
            setDataUpload(null);
            dispatch(setToggleMailing(false));
          }}
          disabled={loadingToSend}
        >
          Annuler
        </Button>
        <LoadingButtonCustom {...loadingButtonProps} />
      </Box>
    </Box>
  );

  return (
    <Modal
      className={classes.modal}
      open={showMailingModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showMailingModal}>{body}</Fade>
    </Modal>
  );
}

export default withSnackbar(MailingModal);
