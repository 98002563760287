import React from "react";
import { Box, Button, IconButton, useMediaQuery } from "@material-ui/core";
import { ImportExport } from "@material-ui/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import peoplefineLogo from "../../assets/images/peoplefine-logo-reporting.png";
import { theme } from "../../utils/Theme";

export const DownloadPDFFile = ({ csvData, fileName, clientName }) => {
  const exportToPDF = () => {
    const doc = new jsPDF("landscape");
    console.log(csvData)
    const tableColumnTitles = [
      "N°",
      "Candidat",
      // "Consultant",
      " Mission",
      // "Statut",
      "Envoi",
      "Révélation",
     // "Propale",
      // "Signature",
      // "Commentaires",
      "E1",
      "E2",
      "E3",
      "E4",
      "E5",
      "Propale",
    ];
    const csvDataSorted = csvData.sort((a, b) => {
      const date1 = a.sent;
      const date2 = b.sent;

      if (date1 < date2) {
        return -1;
      }
      if (date1 > date2) {
        return 1;
      }
      return 0;
    });


    const tableData = csvDataSorted.map((value, index) => {
      return [
        index + 1,
        value.condidatName,
        // value.consultant,
        value.nom_mission,
        // value.statut === "ACTIVE" ? "A" : "P",
        value.sent
          ? moment(value.sent).format("DD/MM/yyyy") : "-",
        value.revealed
          ? moment(value.revealed).format("DD/MM/yyyy") : "-",
        value.propale
          ? value.propale
          : "-",
        // "-",
        // "-",
        value.e1 ? moment(value.e1).format("DD/MM/yyyy") : "-",
        value.e2 ? moment(value.e2).format("DD/MM/yyyy") : "-",
        value.e3 ? moment(value.e3).format("DD/MM/yyyy") : "-",
        value.e4 ? moment(value.e4).format("DD/MM/yyyy") : "-",
        value.e5 ? moment(value.e5).format("DD/MM/yyyy") : "-",
      ];
    });
    const logoWidth = 50;
    const logoX = 13;
    const logoY = 8;
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.addImage(peoplefineLogo, "PNG", logoX, logoY - 1, logoWidth, 15);

    let title = `REPORTING PEOPLEFINE - ${clientName} - ${moment().format(
      "D/MM/YYYY"
    )}`;
    if (!clientName) {
      title = `REPORTING PEOPLEFINE - ${moment().format("D/MM/YYYY")}`;
    }

    const titleWidth =
      (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const startX = (pageWidth - titleWidth) / 2;
    doc.setFont("bold");
    doc.text(title, startX, 25);
    doc.setFontSize(16);

    const headerText = "";
    // const headerText = "Statut: A (Active) / P (Passive)";
    const headerTextWidth = doc.getStringUnitWidth(headerText) * 10;
    const headerX = pageWidth - headerTextWidth + 70;
    const headerY = 10;

    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(headerText, headerX, headerY);
    doc.autoTable({
      head: [tableColumnTitles],
      body: tableData,
      startY: 30,
      theme: "grid",
      styles: { fontSize: 10, valign: "middle", halign: "center" },
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#000",
        lineWidth: 0.1,
        lineColor: "#000",
        fontSize: 10,
        valign: "middle",
        halign: "start",
      },
      bodyStyles: {
        halign: "start",
        valign: "middle",
        fontSize: 10,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      cellStyles: { overflow: "linebreak", fontSize: 11 },
    });


    doc.setTextColor(0);

    const addressText = "Adr: 66 avenue des Champs-Elysées, 75008 PARIS";
    const emailText = "Email: contact@peoplefine.com";
    const siteWeb = "Site: www.peoplefine.com";
    const phone = "Tél: +33 1 82 82 98 30";

    const footerTexts = [addressText, emailText, siteWeb, phone];
    const startY = doc.internal.pageSize.getHeight() - 20;

    footerTexts.forEach((text, index) => {
      const y = startY + (index * 5);
      const x = 138 - (doc.getStringUnitWidth(text) * 2);
      doc.text(text, x, y);
    });

    doc.save(`REPORTING PEOPLEFINE-${clientName}-${fileName}.pdf`);
  };

  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      {!isSmMode ? (
        <Button
          startIcon={<ImportExport />}
          style={{ backgroundColor: "rgb(17, 122, 167)", color: "#FFF" }}
          onClick={exportToPDF}
        >
          Export
        </Button>
      ) : (
        <IconButton data-toggle="tooltip"
          title="Exporter" onClick={exportToPDF} size="medium">
          <ImportExport />
        </IconButton>
      )}
    </Box>
  );
};

export default DownloadPDFFile;
