import api from "../../utils/API";

const getAllProcesses = (candidat, page, limit, statut) => {
  return api.get(`/processes/candidats/${candidat}/process/list?page=${page}&limit=${limit}&statut=${statut}`);
};

const addProcess = (data) => {
  return api.post(`/processes/candidats/process/add`, data);
};
const addCandidatsInProcess = (data) => {
  return api.post(`/processes/mission/candidats/process/add`, data)
};
const sendProcess = (data) => {
  return api.post(`/process/processes/send`, data);
};
const sendEmailCandidat = (data) => {
  return api.post(`/candidat/email/send`, data);
};
const sendEmailContact = (data) => {
  return api.post(`/contact/email/send`, data);
};

const revealProcess = (data) => {
  return api.post(`/process/processes/reveal`, data);
};

const deleteProcess = (proces) => {
  return api.delete(`/process/processes/${proces}/delete`);
};

export { getAllProcesses, addProcess, sendEmailContact, addCandidatsInProcess, sendProcess, revealProcess, deleteProcess, sendEmailCandidat };
